<template>
  <div class="flex-grow-1">
    <admin-title title="General Settings"></admin-title>

    <v-card class="custom-shadow" max-width="1000" outlined>
      <v-form ref="settingsForm">
        <v-simple-table class="setting-table">
          <template #default>
            <thead>
              <tr>
                <th width="33%">Name</th>
                <th width="34%">Description</th>
                <th width="33%">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Page Builder Marketing Banner</td>
                <td class="caption">
                  The number of panels the subscriber needs to add in their project before they see the promotional banner.
                </td>
                <td>
                  <v-text-field
                    v-model.number="settings.pageBanner"
                    :rules="[rules.required]"
                    type="number"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td>PDF Downloads</td>
                <td class="caption">
                  The number of PDF Downloads a user needs to make before triggering a tag to be added in Active Campaign.
                </td>
                <td>
                  <v-text-field
                    v-model.number="settings.pdfDownloads"
                    :rules="[rules.required]"
                    type="number"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td>Max projects per regular user</td>
                <td class="caption">
                  The number of projects a subscriber is allowed to create in Lightning Builder.
                </td>
                <td>
                  <v-text-field
                    v-model.number="settings.maxProjects"
                    :rules="[rules.required]"
                    type="number"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td>Max projects per client</td>
                <td class="caption">
                  The number of projects a client is allowed to create in Lightning Builder.
                </td>
                <td>
                  <v-text-field
                    v-model.number="settings.maxClientProjects"
                    :rules="[rules.required]"
                    type="number"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td>Good feedback webhook URL</td>
                <td class="caption">
                  None
                </td>
                <td>
                  <v-text-field
                    v-model.number="settings.goodFeedback"
                    :rules="[rules.required]"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td>Bad feedback webhook URL</td>
                <td class="caption">
                  None
                </td>
                <td>
                  <v-text-field
                    v-model.number="settings.badFeedback"
                    :rules="[rules.required]"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </td>
              </tr>

              <!-- USERS -->
              <tr>
                <td colspan="3" class="font-weight-bold primary--text">
                  Users
                </td>
              </tr>
              <tr>
                <td>New User Recipients</td>
                <td>
                  Notify provided emails for new user registration.
                </td>
                <td>
                  <v-combobox
                    v-model="settings.newUsersRecipients"
                    :rules="[rules.required]"
                    class="gap-1"
                    deletable-chips
                    hide-details
                    small-chips
                    multiple
                    outlined
                    dense
                    chips
                  ></v-combobox>
                </td>
              </tr>

              <!-- ACTIVE CAMPAIGN SETTINGS -->
              <tr>
                <td colspan="3" class="font-weight-bold primary--text">
                  Active Campaign
                </td>
              </tr>
              <tr>
                <td colspan="2">AC API URL</td>
                <td>
                  <v-text-field
                    v-model="settings.acApiUrl"
                    :rules="[rules.required, rules.url]"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td colspan="2">AC API Key</td>
                <td>
                  <v-text-field
                    v-model="settings.acApiKey"
                    :rules="[rules.required]"
                    type="password"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td>PDF Download AC Tag</td>
                <td class="caption">
                  The tag that will be added in Active Campaign once the user meets the number of PDF Downloads required.
                </td>
                <td>
                  <v-text-field
                    v-model="settings.pdfAcTag"
                    :rules="[rules.required]"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td>New User AC Tag</td>
                <td class="caption">
                  The tag that is added along with the contact details of every new Lightning Builder subscriber.
                </td>
                <td>
                  <v-text-field
                    v-model="settings.newUserTag"
                    :rules="[rules.required]"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </td>
              </tr>

              <!-- REQUEST SETTINGS -->
              <tr>
                <td colspan="3" class="font-weight-bold primary--text">
                  Request
                </td>
              </tr>
              <tr>
                <td>Message Response Time Limit</td>
                <td class="caption">
                  ---
                </td>
                <td>
                  <v-text-field
                    :suffix="pluralize('hour', settings.requestResponseTime || 1)"
                    v-model.number="settings.requestResponseTime"
                    :rules="[rules.required]"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td>Allowed Active Requests</td>
                <td class="caption">
                  Customers can only have X number of Active Requests (Active means: all status types except "Completed" and "Paused"). Then they can’t submit a request without marking some requests as completed.
                </td>
                <td>
                  <v-text-field
                    v-model.number="settings.allowedActiveRequests"
                    :rules="[rules.required]"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td>Escalated Status</td>
                <td class="caption">
                  A ticket is marked as Escalated when a ticket is past X days from the due date. In other words, if this was left as 0 it would mean that the ticket would escalate the day after it was due.
                </td>
                <td>
                  <v-text-field
                    v-model.number="settings.escalateRequests"
                    suffix="days"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </td>
              </tr>

              <!-- ORDER SETTINGS -->
              <tr>
                <td colspan="3" class="font-weight-bold primary--text">
                  Order
                </td>
              </tr>
              <tr>
                <td>Message Response Time Limit</td>
                <td class="caption">
                  ---
                </td>
                <td>
                  <v-text-field
                    :suffix="pluralize('hour', settings.orderResponseTime || 1)"
                    v-model.number="settings.orderResponseTime"
                    :rules="[rules.required]"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-form>
      <v-divider></v-divider>
      <v-card-actions class="pa-3">
        <v-btn
          @click="validateForm()"
          :loading="status.saving"
          class="accent gradient"
        >Save Settings</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import rules from '@/rules'
import pluralize from 'pluralize'
import { mapState, mapActions } from 'vuex'

export default {
  metaInfo: {
    title: 'General Settings'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      pluralize,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      settings: (state) => state.generalSettings.settings,
      status: (state) => state.generalSettings.status,
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('generalSettings', [
      'saveSettings',
      'getSettings'
    ]),

    validateForm() {
      if (this.$refs.settingsForm.validate()) {
        this.saveSettings()
      }
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getSettings()
  }
}
</script>

<style lang="scss">

.setting-table {
  tr {
    td {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }
}

</style>